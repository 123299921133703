import Segment from "segment-js"
import ease from "./ease.min"

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response.url);
});

document.addEventListener("turbo:load", function () {

  const myCarousel = document.getElementById('carouselInstrumente')
  const myCarouselElement = $(myCarousel).find(".carousel-control-bg")
  if (myCarousel != undefined) {

    $(myCarousel).off("mouseover")
    $(myCarousel).off("mouseleave")

    $(myCarousel).on({
      mouseenter: function () {
        //console.log("in")
        myCarouselElement.removeClass("xyz-out")
        myCarouselElement.addClass("xyz-in")
      },
      // mouseleave: function () {
      //   //console.log("out")
      //   $(myCarousel).find(".carousel-control").removeClass("xyz-in hidden")
      //   $(myCarousel).find(".carousel-control").addClass("xyz-out")
      // }
    });

    $("body").off('mouseover')
    $("body").on('mouseover', function(event) {
      if (!myCarousel.contains(event.target)) {
        myCarouselElement.removeClass("xyz-in")
        myCarouselElement.addClass("xyz-out")
      }
    })

    myCarousel.addEventListener('slid.bs.carousel', event => {
      //console.log(event)
      $(event.target).find(".carousel-item").find(".carousel-caption h1").removeClass("xyz-in").addClass("xyz-in")
      main.show_instrument()
    })

    main.show_instrument()

    // const carousel = new bootstrap.Carousel(myCarousel, {
    //   interval: 2000,
    //   touch: false
    // })
  }

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 560) {
      $("section#float-header").addClass("z-index");
    } else {
      $("section#float-header").removeClass("z-index");
    }

    if (scroll >= 620) {
      $("#icon-nav.detail-page").addClass("hidden");
    } else {
      $("#icon-nav.detail-page").removeClass("hidden");
    }

    if (scroll >= 680) {
      $("section#float-header, section#detail-content").addClass("fixed-nav");
    } else {
      $("section#float-header, section#detail-content").removeClass("fixed-nav");
    }

    //float nav hidden
    if ($('section#detail-about').length) {
      var hT = $('section#detail-about').offset().top,
        hH = $('section#detail-about').outerHeight(),
        wH = $(window).height(),
        wS = $(this).scrollTop();
      if (wS > (hT + hH - wH)) {
        $('section#float-header').addClass("nav-out");
        $('#icon-nav').addClass("icon-in");
      } else {
        $('section#float-header').removeClass("nav-out");
        $('#icon-nav').removeClass("icon-in");
      }
    }
  });


  $(function () {
    //loading
    setTimeout(function () {
      $("aside#loading").fadeOut().remove();
    }, 900);

    //header title full screen
    function i() {
      var i = $(window).height();
      $("section#fixed-animation").css({
        minHeight: i
      });
    }
    i();
    $(window).resize(function () {
      i();
    });

    function handleResize() {
      var mediaHeight = $(window).height();
      //$("header#home-header, header#home-header .wrap, header#home-header .header-title").css({height: mediaHeight});
    }
    handleResize();
    $(window).resize(function () {
      handleResize();
    });

    /* In animations (to close icon) */

    var beginAC = 80,
      endAC = 320,
      beginB = 80,
      endB = 320;

    function inAC(s) {
      s.draw('80% - 240', '80%', 0.3, {
        delay: 0.1,
        callback: function () {
          inAC2(s)
        }
      });
    }

    function inAC2(s) {
      s.draw('100% - 545', '100% - 305', 0.6, {
        easing: ease.ease('elastic-out', 1, 0.3)
      });
    }

    function inB(s) {
      s.draw(beginB - 60, endB + 60, 0.1, {
        callback: function () {
          inB2(s)
        }
      });
    }

    function inB2(s) {
      s.draw(beginB + 120, endB - 120, 0.3, {
        easing: ease.ease('bounce-out', 1, 0.3)
      });
    }

    /* Out animations (to burger icon) */

    function outAC(s) {
      s.draw('90% - 240', '90%', 0.1, {
        easing: ease.ease('elastic-in', 1, 0.3),
        callback: function () {
          outAC2(s)
        }
      });
    }

    function outAC2(s) {
      s.draw('20% - 240', '20%', 0.3, {
        callback: function () {
          outAC3(s)
        }
      });
    }

    function outAC3(s) {
      s.draw(beginAC, endAC, 0.7, {
        easing: ease.ease('elastic-out', 1, 0.3)
      });
    }

    function outB(s) {
      s.draw(beginB, endB, 0.7, {
        delay: 0.1,
        easing: ease.ease('elastic-out', 2, 0.4)
      });
    }

    /* Awesome burger default */

    var pathA = document.getElementById('pathA'),
      pathB = document.getElementById('pathB'),
      pathC = document.getElementById('pathC'),
      segmentA = new Segment(pathA, beginAC, endAC),
      segmentB = new Segment(pathB, beginB, endB),
      segmentC = new Segment(pathC, beginAC, endAC),
      toCloseIcon = true,
      wrapper = document.getElementById('menu-icon-wrapper');

    wrapper.style.visibility = 'visible';

    //navigation
    $("#icon-nav").click(function () {
      inAC(segmentA);
      inB(segmentB);
      inAC(segmentC);

      $("a.open-navigation").addClass("x");
      $("aside#circle > span").addClass("x");
      $("aside#fade-bg").fadeIn().addClass("x");
      $("aside#navigation").addClass("x");
      return false;
    });

    $("a.open-navigation > div").click(function () {
      outAC(segmentA);
      outB(segmentB);
      outAC(segmentC);
      $(this).parent().removeClass("x");
      $("aside#circle > span").addClass("xy");
      $("aside#fade-bg").fadeOut().removeClass("x");
      $("aside#navigation").removeClass("x");
      setTimeout(function () {
        $("aside#circle > span").removeClass("x xy");
        $("aside#fade-bg");
      }, 280);
      return false;
    });

    $('aside#fade-bg').click(function (e) {
      outAC(segmentA);
      outB(segmentB);
      outAC(segmentC);

      if ($(e.target).is('aside#fade-bg')) {
        $("a.open-navigation").removeClass("x");
        $(this).fadeOut().removeClass("x");
        $("aside#circle > span").addClass("xy");
        $("aside#navigation").removeClass("x");
        setTimeout(function () {
          $("aside#circle > span").removeClass("x xy");
        }, 280);
        return false;
      }
    });

    //category menu
    $('.roll-list > a').click(function (e) {
      $(this).toggleClass("x");
      $(".roll-list > ul").fadeToggle(200);
      return false;
    });
    $('.roll-list > a').on('click', function (f) {
      f.stopPropagation();
    });
    $(document).on('click', function (f) {
      $(".roll-list > a").removeClass("x");
      $(".roll-list > ul").fadeOut(200);
    });

    // course pagination
    $(document).on('click', '#list-content .load-more-items', function (e) {
      e.preventDefault();

      var el = $(this);

      el.addClass('x');

      $.get(el.attr('href'), function (data) {
        $(data.html).hide().appendTo('#list-content .all-events').fadeIn('slow');

        if (data.hasMorePages) {
          el.attr('href', data.nextPageUrl);
          el.removeClass('x');
        } else {
          el.remove();
        }
      }, 'json');
    });

    //parallax
    var elements = $('[data-parallax="true"]');

    function draw() {
      requestAnimationFrame(draw);
      scrollEvent();
    }
    $(window).resize(draw);

    function scrollEvent() {

      if (!is_touch_device()) {
        let viewportTop = $(window).scrollTop();
        let windowHeight = $(window).height();
        let viewportBottom = windowHeight + viewportTop;

        if ($(window).width())

          elements.each(function () {

            if ($(window).width() < 900) {
              $(this).attr('data-parallax', false);
              $(this).css('transform', 'translate3d(0,0,0)');
              return;
            } else {
              $(this).attr('data-parallax', 'true');
            }

            let sym = null;

            let distance = viewportTop * $(this).attr('data-scroll-speed');
            if ($(this).attr('data-direction') === 'up') {
              sym = '-';
            } else {
              sym = '';
            }
            $(this).css('transform', 'translate3d(0, ' + sym + distance + 'px,0)');
          });

      }
    }
    draw();
    //touch parallax
    function is_touch_device() {
      //return 'ontouchstart' in window
      //|| 'onmsgesturechange' in window;
    }

    //clones
    $(".author").clone().appendTo(".copyright-nav .wrap");
    // $("section#fixed-animation").clone().appendTo("header#home-header").addClass("ahoj");
    //$("ul.category a.active > span.title").clone().appendTo(".roll-list > a");

    //nosvg
    if ($("html").hasClass("no-svg")) {
      $('img.flb-img[src*="svg"]').attr('src', function () {
        return $(this).attr('src').replace('.svg', '.png');
      });
    }

    // click to scroll
    $("a.scroll-to-form").click(function () {
      $('html, body').animate({
        scrollTop: $("section#detail-form").offset().top
      }, 1000);
      return false;
    });

    //form
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $(document).off('focus')
    $(document).on('focus', '.js_reg_form label input, .js_reg_form textarea, .js_reg_form label > select', function () {
        $(this).parent().toggleClass("write")
        $(this).parent().removeClass("wrong");
      })
    $(document).off('blur')
    $(document).on('blur', '.js_reg_form label input, .js_reg_form textarea, .js_reg_form label > select', function () {
        $(".js_reg_form label").removeClass("write");
      });

    $(document).on('submit', 'form.js_reg_form', function (e) {
      e.preventDefault();

      var form = $(this);

      if (!form.hasClass('disabled')) {
        clearTimeout(window.intervalAlertMessage);
        $('.alert-message').removeClass('visible').removeAttr('style');

        form.addClass('disabled');

        $.ajax({
          type: 'post',
          url: form.attr('action'),
          data: form.serialize(),
          dataType: 'json',
          success: function (data) {
            if (data.success) {
              form.trigger('reset');

              form.removeClass('disabled');

              $('#true-message').addClass('visible');

              window.intervalAlertMessage = setTimeout(function () {
                $('#true-message').fadeOut(350, function () {
                  $(this).removeClass('visible').removeAttr('style');
                });
              }, 6000);

            }
          },
          error: function (xhr, status, error) {
            form.removeClass('disabled');

            //console.log(xhr.responseJSON)

            $.each(xhr.responseJSON.errors, function (index, val) {
              $('#form-' + val).addClass('wrong');
            });

            $('#false-message').addClass('visible');

            window.intervalAlertMessage = setTimeout(function () {
              $('#false-message').fadeOut(350, function () {
                $(this).removeClass('visible').removeAttr('style');
              });
            }, 6000);
          }
        });
      }
    });
  });

  // ms viewport
  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement("style");
    msViewportStyle.appendChild(
      document.createTextNode(
        "@-ms-viewport{width:auto!important}"
      )
    );
    document.getElementsByTagName("head")[0].
    appendChild(msViewportStyle);
  }

  function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

})

var main = { 
  show_instrument() {
    $("section#instrumente .grid").hide()
    $($(".carousel-item.active").find(".more").attr("href")).show()
  }
}

window.main = main
