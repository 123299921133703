// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import './vendor/jquery'

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import Segment from "segment-js"
window.Segment = Segment

import MediumEditor from 'medium-editor/dist/js/medium-editor.min'
window.MediumEditor = MediumEditor
import AutoList from 'medium-editor-autolist/dist/autolist.min'
window.AutoList = AutoList

import "./js/**/*"
